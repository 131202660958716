import { graphql } from "gatsby"
import React from "react"

import Products, { contentPosition } from "../components/Cards/Products"
import Img from "../components/common/Img"
import ImageHero from "../components/ImageHero/ImageHero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectKidsMenuPageContent,
  selectProductGroups,
  selectProducts,
  selectTranslations,
} from "../graphql/selectors"
import { findPageSlugByPath } from "../utils/urls"
import s from "./kids-menu.module.scss"

export default function KidsMenuPage(props) {
  const { data, pageContext } = props
  const translations = selectTranslations(data)
  const { langCode, locale, localePath, pagePathsByLangCode } = pageContext
  const { seo, hero, productsSection, description } = selectKidsMenuPageContent(
    data
  )
  const specialGroupId = "kids-menu"
  const specialGroupSlug = findPageSlugByPath(
    translations.pages,
    `/${specialGroupId}/`
  )
  const products = selectProducts(data).filter(
    (product) => product.groups.indexOf(specialGroupId) >= 0
  )

  return (
    <Layout
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <ImageHero
        alt={hero.alt}
        className={s.hero}
        imageUrl={hero.imageUrl}
        showBottomEdgeLine={true}
      />
      <ImageHero
        alt={hero.alt}
        className={s.heroSm}
        imageUrl={hero.imageUrlSm}
        showBottomEdgeLine={true}
      />
      <section className={s.content}>
        <div className={s.row}>
          <section className={s.mealBox}>
            <Img
              alt={hero.alt}
              className={s.productImage}
              objectFit="contain"
              placeholderHidden
              src={hero.mealBoxImageUrl}
            />
          </section>
          <section className={s.description}>
            <h1 className={s.title}>{description.title}</h1>
            <p className={s.primaryText}>{description.primaryText}</p>
            <h2 className={s.subtitle}>{description.subtitle}</h2>
            <p className={s.secondaryText}>{description.secondaryText}</p>
          </section>
        </div>
      </section>
      <section className={s.productsSection}>
        <h2 className={s.productsTitle}>{productsSection.title}</h2>
        <div className={s.content}>
          <Products
            locale={locale}
            position={contentPosition.center}
            productGroupSlug={specialGroupSlug}
            products={products}
          />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query KidsMenuPage($locale: String) {
    ...KidsMenuPageFragment
    ...LayoutFragment
    ...ProductFragment
    ...TranslationsFragment
  }
`
